export const CONTESTANTS = {
  REGISTER_CONTESTANT: 'REGISTER_CONTESTANT',
  LOGIN_CONTESTANT: 'LOGIN_CONTESTANT',
  GET_ALL_CONTESTANTS: 'GET_ALL_CONTESTANTS',
  GET_ONE_CONTESTANTS: 'GET_ONE_CONTESTANTS',
};

export const PAYMENT = {
  START_PAYMENT: 'START_PAYMENT',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
  GET_PAYMENT: 'GET_PAYMENT',
};
