import React from 'react';
import { LoginForm } from '../../components/forms/LoginForm';

const Login = () => {
  return (
    <div style={{ paddingTop: '150px' }}>
      <LoginForm />
    </div>
  );
};

export default Login;
